<template>
  <v-container class="container-page-error pa-0 ma-0">
    <div class="d-flex flex-column text text-center">
      <h2>404</h2>
      <span class="my-6">
        OOOPPS.! La page que vous recherchez est introuvable.
      </span>
      <div class="mt-6">
         <router-link
          class="router-link"
          to="/home"
        >
          Revenir à l'accueil
        </router-link>
        <v-icon color="white">mdi-menu-right</v-icon>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'LayoutFull'
}
</script>

<style scoped>
.container-page-error {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(../assets/images/photo-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-width: 100% !important;
}
.text {
  margin: auto;
  padding-top: 16rem;
  color: white;
  font-family: source sans pro, sans-serif !important;
  text-transform: uppercase;
}
h2 {
  font-size: 80px;
}
span {
  font-size: 24px;
  font-weight: 500;
}
.router-link {
  color: white;
  font-size: 20px;
}
</style>